<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxBacktest">
        <md-card-header
          class="md-card-header-icon md-card-header-blue skynetIcon"
        >
          <div class="card-icon card">
            <span class="material-symbols-outlined icon"> rocket_launch </span>
          </div>
        </md-card-header>
        <md-card-content class="label">
          <TituloBox
            :label="'Configuração do parâmetro Variável Amostra:'"
            :iconLabel="'task_alt'"
            :textoTooltip="'Selecione quais as variaveis amostras que serão utilizadas'"
          />
        </md-card-content>
        <md-card-content class="fieldAmostra">
          <TabsVarAmostra
            @variavelAmostra="variavelAmostra"
            @validaAndRecebe="validaAndRecebe"
            :varRespSelecionada="varRespSelecionada"
            :validaAmostras="validaAmostras"
            :liberaSafraDataRef="liberaSafraDataRef"
          />
        </md-card-content>
        <md-card-content class="footer">
          <button class="button-backstest" @click="goInteg1">ANTERIOR</button>
          <button
            class="button-backstest"
            @click="goInteg3"
            :disabled="!validaAmostras || !varAmostraSelecionada"
          >
            PRÓXIMA
          </button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import TituloBox from "@/components/Visuais/TituloBox.vue";
import TabsVarAmostra from "@/components/Integracao/TabsVarAmostra.vue";

export default {
  name: "VariavelResposta",

  components: {
    TituloBox,
    TabsVarAmostra,
  },

  props: {
    varRespSelecionada: String,
    projectName: String,
    liberaSafraDataRef: Boolean,
  },

  data() {
    return {
      identificador: this.$route.params.id,
      skynetLogo: require("/public/img/Skynet.png"),
      validaAmostras: false,
      varAmostraSelecionada: "",
      trainSampleSelecionada: "",
      resultAmostra: {},
    };
  },

  methods: {
    variavelAmostra(a) {
      this.varAmostraSelecionada = a;
      this.$emit("variavelAmostra", this.varAmostraSelecionada);
    },

    validaAndRecebe(v, trainSample) {
      this.resultAmostra = trainSample;
      this.validaAmostras = v;
    },

    goInteg1() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/1");
    },
    goInteg3() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/3");
    },
  },

  created() {
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  min-height: 100%;
  @include flex-center(column);
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.card {
  padding: 10px !important;
  border: none;
  height: 64px !important;
  top: -1rem;
}

.boxBacktest {
  border: 1px solid var(--accent-60);
  border-radius: 20px;
  padding: 0 0 0 0 !important;
  min-height: 350px;
  height: 500px;
  max-height: 950px;
  height: 100%;
  width: 90% !important;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  background-color: #f9f7ff;

  flex-direction: column !important;
  @include mediaQueryMax(medium) {
    min-height: 700px;
    max-height: 2000px;
  }
  @include mediaQueryMax(small) {
    min-height: 700px;
    max-height: 2000px;
    width: 85% !important;
  }
}
.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 0px;
  @include mediaQueryMax(small) {
    display: none;
  }
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.label {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0 0 !important;
  padding: 0px 20px;
  width: 100%;
}
.fieldAmostra {
  min-height: 250px;
  display: flex;
  justify-content: flex-start;
  margin: 0% !important;
  width: 100%;
  padding: 0.5rem 1.5rem;
}
.footer {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  margin: 0;
  padding: 0 15px 15px 15px;

  @include mediaQueryMax(small) {
  flex-direction: column;
  }
}

.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}
</style>