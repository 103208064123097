<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxBacktest">
        <md-card-header
          class="md-card-header-icon md-card-header-blue skynetIcon"
        >
          <div class="card-icon card">
            <span class="material-symbols-outlined icon"> rocket_launch </span>
          </div>
        </md-card-header>
        <md-card-content class="label">
          <TituloBox
            :label="'Configuração da Liguagem e da Abordagem para a integração do Lote:'"
            :iconLabel="'task_alt'"
          />
        </md-card-content>
        <md-card-content class="field">
          <Abordagem
            @recebeLing="recebeLing"
            @recebeAbordagem="recebeAbordagem"
          />
        </md-card-content>
        <md-card-content class="footer">
          <button class="button-backstest" @click="goInteg3">ANTERIOR</button>
          <button class="button-backstest" @click="goInteg5" :disabled="libera">
            PRÓXIMA
          </button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import TituloBox from "@/components/Visuais/TituloBox.vue";
import Abordagem from "@/components/Integracao/Abordagem.vue";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState, mapActions } from "pinia";

export default {
  name: "LinguagemEAbordagemPreditiva",

  components: {
    TituloBox,
    Abordagem,
  },
  props: {
    projectName: String,
  },

  data() {
    return {
      identificador: this.$route.params.id,
      libera: true,
      skynetLogo: require("/public/img/Skynet.png"),
      linguagemSelecionada: "",
      abordagemSelecionada: {},
    };
  },

  methods: {
    goInteg3() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/3");
    },
    goInteg5() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/5");
    },
    recebeLing(linguagem) {
      this.linguagemSelecionada = linguagem;
      this.liberaButton();
    },
    recebeAbordagem(abordagem) {
      this.abordagemSelecionada = abordagem;
      this.liberaButton();
      this.setAbordagem(this.abordagemSelecionada);
    },
    liberaButton() {
      let check = false;
      for (let i in this.abordagemSelecionada) {
        if (this.abordagemSelecionada[i] == true) {
          check = true;
          break;
        }
      }
      if (this.linguagemSelecionada != "" && check == true) this.libera = false;
      else this.libera = true;
    },
    ...mapActions(useSkynetMemoryStore, ["setAbordagem"]),
  },
  computed: {
  },

  created() {
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },
};
</script>

<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  min-height: 100%;
  @include flex-center(column);
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.card {
  padding: 10px !important;
  border: none;
  height: 64px !important;
  top: -1rem;
}
.boxBacktest {
  border: 1px solid var(--accent-60);
  border-radius: 20px;
  padding: 0 0 0 0 !important;
  min-height: 400px;
  width: 80% !important;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  background-color: #f9f7ff;
  flex-direction: column !important;
  @include mediaQueryMax(small) {
    min-height: 500px;
    width: 85% !important;
  }
}
.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 0px;
  @include mediaQueryMax(small) {
    display: none;
  }
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.label {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0 0 !important;
  padding: 0px 20px;
  width: 100%;
}
.field {
  min-height: 17rem;
  display: flex;
  margin: 0%;
  width: 100%;
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  @include mediaQueryMax(small) {
    flex-direction: column;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }
}
.footer {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}

.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}
</style>