<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxBacktest">
        <md-card-header
          class="md-card-header-icon md-card-header-blue skynetIcon"
        >
          <div class="card-icon card">
            <span class="material-symbols-outlined icon"> rocket_launch </span>
          </div>
        </md-card-header>
        <md-card-content class="label">
          <TituloBox
            :label="'Configuração das Variáveis Chaves:'"
            :iconLabel="'task_alt'"
          />
        </md-card-content>
        <md-card-content class="field">
          <div class="left">
            <div class="covarBox" :class="{ covarBoxDisable: !checkReserv }">
              <VariavelReservada
                @recebeCheckReserv="recebeCheckReserv"
                :cabecalhoSaidaSelect="cabecalhoSaidaSelect"
                :libera="libera"
                :checkReserv="checkReserv"
              />
            </div>
          </div>
          <div class="right">
            <div
              class="covarBox"
              :class="{ covarBoxDisable: !checkCovariavel }"
            >
              <Covariaveis
                @checkCovar="checkCovar"
                :cabecalhoSaidaSelect="cabecalhoSaidaSelect"
                :libera="libera"
                :checkCovariavel="checkCovariavel"
              />
            </div>
          </div>
        </md-card-content>
        <md-card-content class="footer">
          <button class="button-backstest" @click="goInteg2">ANTERIOR</button>
          <button
            class="button-backstest"
            @click="goInteg4"
            :disabled="
              (checkReserv && this.varReservadasMemory.length == 0) ||
              (checkCovariavel && this.covarSelecionadasMemory.length == 0)
            "
          >
            PRÓXIMA
          </button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import TituloBox from "@/components/Visuais/TituloBox.vue";
import { lotesService } from "@/services";
import { mapState, mapActions } from "pinia";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";

import VariavelReservada from "@/components/Integracao/varReservada.vue";
import Covariaveis from "@/components/Integracao/Covariaveis.vue";

export default {
  name: "VariavelResposta",

  components: {
    TituloBox,
    VariavelReservada,
    Covariaveis,
  },
  props: {
    projectName: String,
    resLote: Array,
  },

  data() {
    return {
      identificador: this.$route.params.id,
      skynetLogo: require("/public/img/Skynet.png"),
      cabecalhoSaida: [],
      atrSaida: [],
      cabecalhoSaidaFiltrado: [],
      cabecalhoSaidaSelect: [],
      cabecalhoTotal: [],
      preSelecionadas: [],
      libera: false,
      varReservadasSelecionadas: [],
      covarSelecionadas: [],
      checkCovariavel: false,
      checkReserv: false,
    };
  },

  methods: {
    async showLayoutBox() {
      this.$forceUpdate()
      this.libera = true;
      const rotaProduto =
      this.rotaConsulta == "Box de Modelos"
      ? "v3/modelo"
      : "v3/box";

      const objetoHistorico = {
        produto: rotaProduto,
        cod_ref: this.abordagemConsulta,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutChoosedBox = await lotesService.recuperaLayoutLotes(
        query.toString()
      );

      this.cabecalhoSaida = this.layoutChoosedBox.CabecalhoSaida.split("; ");

      this.availableCols.forEach((coluna) => {
        this.cabecalhoSaidaSelect.push(coluna);
      });
      this.cabecalhoSaida.forEach((key) => {
        this.atrSaida = key.split(";");
      });
      this.atrSaida.forEach((atributo) => {
        // atributo = atributo.replaceAll(".", "_");
        if (atributo.includes("atributos")) {
          this.cabecalhoSaidaSelect.push(atributo);
        } else {
          this.cabecalhoSaidaFiltrado.push(atributo);
        }
      });
      this.setcabecalhoSaidaSelect(this.cabecalhoSaidaSelect);
      this.initPreSelecionadas(this.cabecalhoSaidaFiltrado);
      this.libera = false;

    },

    checkCovar(c) {
      this.checkCovariavel = c;
    },

    recebeCheckReserv(c) {
      this.checkReserv = c;
    },

    goInteg2() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/2");
    },
    goInteg4() {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/4");
    },

    ...mapActions(useSkynetStore, [
      "setCovarSelect",
      "setcabecalhoSaidaSelect",
      "setCovarReservadas",
      "initPreSelecionadas",
    ]),
  },
  computed: {
    ...mapState(useSkynetStore, [
      "availableCols",
      "selectedColsSkynet",
      "varReservadas",
      "covarReservadas",
      "tipoPessoaMemory",
    ]),
    ...mapState(useSkynetMemoryStore, [
      "varReservadasMemory",
      "covarSelecionadasMemory",
      "rotaConsulta",
      "abordagemConsulta",
    ]),
  },

  created() {
    this.showLayoutBox();
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },
};
</script>


<style  lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  min-height: 100%;
  @include flex-center(column);
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}
.card {
  padding: 10px !important;
  border: none;
  height: 64px !important;
  top: -1rem;
}
.boxBacktest {
  border: 1px solid var(--accent-60);
  border-radius: 20px;
  padding: 0 0 0 0 !important;
  min-height: 400px;
  // max-height: 400px;
  width: 90% !important;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  background-color: #f9f7ff;
  flex-direction: column !important;
  @include mediaQueryMax(small) {
    min-height: 500px;
    width: 85% !important;
  }
}
.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 0px;
  @include mediaQueryMax(small) {
    display: none;
  }
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.label {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0px 0 0 !important;
  padding: 0px 20px;
  width: 100%;
}
.field {
  min-height: 23rem;
  display: flex;
  margin: 0%;
  width: 100%;
  padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  @include mediaQueryMax(medium) {
    flex-direction: column;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }
  @include mediaQueryMax(small) {
    flex-direction: column;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
  }
}
.footer {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;

  @include mediaQueryMax(small) {
    flex-direction: column;
  }
}
.left {
  min-height: 100%;
  width: 50%;
  margin: 0 5px 0 0;
  display: flex;
  justify-content: space-between;

  @include mediaQueryMax(medium) {
    width: 100%;
  }
  @include mediaQueryMax(small) {
    width: 100%;
    
  }
}
.right {
  width: 50%;
  margin: 0 0 0 5px;
  min-height: 100%;
  gap: 1rem;
  @include flex-center(column);
  justify-content: space-between;

  @include mediaQueryMax(medium) {
    width: 100%;
    margin: 1rem 0 0 0;
    min-height: 200px;
  }
  @include mediaQueryMax(small) {
    width: 100%;
    margin: 1rem 0 0 0;
    min-height: 200px;
  }
}

.covarBox {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 6px;
  padding: 0 10px 10px 10px;
  transition: 1s ease all;
  @include mediaQueryMax(small) {
    min-height: 200px;
  }
}
.covarBoxDisable {
  height: 100%;
  background-color: rgba(65, 74, 87, 0.1);
  transition: 1s ease all;
  cursor: not-allowed;
}
.checkConfirm {
  cursor: pointer !important;
  padding: 5px;
  z-index: 10;
}
.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}
</style>
