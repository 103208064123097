<template>
  <div class="inicial-container">
    <h3 class="disclaimer">
      Integração com o <img :src="skynetLogo" class="skynetLogo" />
      do Projeto:
    </h3>
    <h3 class="disclaimer id">"{{ projectName }}"</h3>
    <div class="content container-fluid text-center">
      <md-card class="boxBacktestExt">
        <md-card class="boxBacktest">
          <md-card-header
            class="md-card-header-icon md-card-header-blue skynetIcon"
          >
            <div class="card-icon card">
              <span class="material-symbols-outlined icon">
                rocket_launch
              </span>
            </div>
          </md-card-header>
          <md-card-content class="label">
            <TituloBox
              :label="'Status das Integrações:'"
              :iconLabel="'task_alt'"
            />
          </md-card-content>
          <md-card-content class="field">
            <div
              class="fieldUp"
              v-if="
                !msgErroIntegracaoMemory &&
                execucoesIntegracao &&
                statusObtido != 'Erro'
              "
            >
              <span class="boxInfoInside">
                <h5 style="margin: 0.5rem 0 0 0">Informações do Lote:</h5>
                <div class="respInfo" id="infoResult">
                  <button
                    class="copy"
                    v-clipboard:copy="identificador"
                    @click="copyInfo"
                  >
                    <span class="material-symbols-outlined copybtn"
                      >content_copy</span
                    >
                    <md-tooltip md-direction="top">
                      Copiar <strong>Identificação</strong></md-tooltip
                    >
                  </button>
                  <strong min-width="90px" class="infoSize"
                    >Identificação:
                  </strong>
                  <span class="infoSize">
                    {{ " " + identificador }}
                  </span>
                </div>
              </span>
              <span class="boxInfoInside">
                <md-card
                  class="fieldStatus"
                  v-if="!msgErroIntegracaoMemory && resultadoIntegracaoMemory"
                >
                  <span
                    class="material-symbols-outlined iconStatus statusSubmetido"
                    v-if="statusObtido == 'Submetido'"
                    >check_circle</span
                  >
                  <span
                    class="material-symbols-outlined iconStatus statusAguardando"
                    v-if="statusObtido == 'Aguardando'"
                    >error</span
                  >
                  <span
                    class="material-symbols-outlined iconStatus statusErro"
                    v-if="statusObtido == 'Erro'"
                    >highlight_off</span
                  >
                  <strong min-width="90px" class="infoSize"
                    >{{ msgAviso }}
                  </strong>
                </md-card>

                <md-card
                  class="fieldStatus"
                  v-if="msgErroIntegracaoMemory && !resultadoIntegracaoMemory"
                >
                  <div class="respError" id="infoResult">
                    <span class="material-symbols-outlined icon-error"
                      >highlight_off</span
                    >
                    <p class="task">
                      <b>Falha na execução da Integração.</b>
                    </p>
                    <div class="infoErro">
                      <strong min-width="90px" class="infoSize"
                        >{{ msgErroIntegracaoMemory }}
                      </strong>
                    </div>
                  </div>
                </md-card>
              </span>
            </div>
            <div class="fieldBottom">
              <md-card class="text-center cardCounter">
                <p class="execText">
                  Foram configuradas
                  <strong>{{ counterMemory }} </strong>execuções
                </p>
              </md-card>
              <div
                class="boxDetails"
                :class="{
                  grid1: this.amostraSeparada.length == 1,
                  grid2: this.amostraSeparada.length == 2,
                  grid4: this.amostraSeparada.length == 4,
                  grid6: this.amostraSeparada.length == 6,
                }"
              >
                <md-card
                  v-for="(amostra, i) in this.amostraSeparada"
                  :key="i"
                  :id="'amostraBox' + i"
                  class="text-center details bg-dark.bg-gradient"
                >
                  <span class="taskExec">
                    <strong class="text-light">Fast Model</strong> <br />
                    <p class="textDetails text-light">
                      {{ modulo[i] }} <br />
                      {{ atributosSeparados[i] }} <br />
                    </p>
                    <strong class="text-light">Variável Amostra</strong>
                    <br />
                    <span>
                      <p class="textDetails text-light">{{ amostra }} <br /></p>
                    </span>
                  </span>
                  <b-tooltip
                    :target="'amostraBox' + i"
                    triggers="hover"
                    placement="bottom"
                    variant="secondary"
                    boundary="viewport"
                    custom-class="tooltipExec"
                  >
                    <strong class="text-light">Fast Model</strong> <br />
                    <span class="text-light">
                      {{ modulo[i] }} <br />
                      {{ atributosSeparados[i] }} <br />
                    </span>
                    <strong class="text-light">Variável Amostra</strong>
                    <br />
                    <span>
                      <span class="text-light">{{ amostra }} <br /></span>
                    </span>
                  </b-tooltip>
                </md-card>
                <!-- </span> -->
              </div>
            </div>
          </md-card-content>
          <md-card-content class="footer">
            <button class="button is-info" @click="goHist">
              <span class="material-symbols-outlined iconMap">send</span>
              <span>HISTÓRICO</span>
            </button>
          </md-card-content>
        </md-card>
      </md-card>
    </div>
    <div>
      <span class="tooltip__content" :class="{ show: flag }">Copiado!</span>
    </div>
  </div>
</template>

<script>
import TituloBox from "@/components/Visuais/TituloBox.vue";
import { historicoService } from "@/services";
import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState, mapActions } from "pinia";
import { skynetService } from "@/services";

export default {
  name: "VariavelResposta",

  components: {
    TituloBox,
  },
  props: {},

  data() {
    return {
      identificador: this.$route.params.id,
      skynetLogo: require("/public/img/Skynet.png"),
      execucoesIntegracao: null,
      msgAviso: "",
      statusObtido: "",
      colunasSeparadas: [],
      modulo: [],
      atributosSeparados: [],
      amostraSeparada: [],
      flag: false,
    };
  },

  methods: {
    async recuperaLoteInfo() {
      const objetoHistorico = { identificacao_execucao: this.identificador };
      const query = new URLSearchParams(objetoHistorico);

      try {
        this.respostaDadosLote =
          await historicoService.recuperaDadosHistoricoLotes(query.toString());
      } catch (error) {}
      this.recuperaIntegracao();
    },

    async recuperaIntegracao() {
      const objetoHistorico = {
        identificacaoExecucao: this.identificador,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.execucoesIntegracao = await skynetService.recuperaDadosIntegracao(
        query.toString()
      );
      if (this.execucoesIntegracao) {
        let execucaoAtual =
          this.execucoesIntegracao.length - this.counterMemory;
        for (
          execucaoAtual;
          execucaoAtual < this.execucoesIntegracao.length;
          execucaoAtual++
        ) {
          if (this.respostaDadosLote[0].pct_completo == 1) {
            this.statusObtido = "Submetido";
            this.msgAviso = "Esta integração está sendo submetida.";
          }
          if (this.respostaDadosLote[0].pct_completo != 1) {
            this.statusObtido = "Aguardando";
            this.msgAviso =
              "Esta integração será submetida após o término da execução do Lote.";
          }
          if (this.execucoesIntegracao[execucaoAtual].status == "Erro") {
            this.statusObtido = "Erro";
            this.msgAviso =
              "Erro ao realizar a execução da Integração deste Lote.";
          }
          if (this.execucoesIntegracao[execucaoAtual].status != "Erro") {
            this.criaNomeAtributos(this.execucoesIntegracao[execucaoAtual]);
          }
        }
      }
    },
    criaNomeAtributos(item) {
      if (item.produto == "FastModel") {
        this.modulo.push("Classic");
        this.atributosSeparados.push("Logistic. Reg.");
      }
      if (item.produto == "MachineLearning") {
        this.modulo.push("Machine Learning");
      }
      this.colunasSeparadas = item.texto_parametros.split(";");
      this.colunasSeparadas.forEach((coluna) => {
        coluna = coluna.split("=");
        if (coluna[0] == "algoritmo") {
          this.atributosSeparados.push(coluna[1]);
        }
        if (coluna[0] == "variavelAmostra") {
          this.amostraSeparada.push(coluna[1]);
        }
      });
    },
    copyInfo() {
      this.flag = true;
      setTimeout(() => {
        this.flag = false;
      }, 1000);
    },

    goHist() {
      this.$router.push("/backtest/historico_backtest");
    },
    ...mapActions(useSkynetMemoryStore, ["setResultadoIntegracao"]),
  },

  created() {
    this.recuperaLoteInfo();
    if (!this.projectName) {
      this.$router.push("/integracoes_skynet/" + this.identificador + "/0");
    }
  },

  computed: {
    ...mapState(useSkynetStore, [
      "varReservadas",
      "dataRefColumn",
      "docColumn",
    ]),

    ...mapState(useSkynetMemoryStore, [
      "projectName",
      "resultadoIntegracaoMemory",
      "msgErroIntegracaoMemory",
      "counterMemory",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.disclaimer {
  font-weight: 500;
  font-size: 1.5rem !important;
  text-align: center;
  margin: 0 !important;
  word-wrap: break-all;
}
.id {
  font-style: italic;
  word-wrap: break-all;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  font-weight: 600;
}
.content {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: row;
  @include mediaQueryMax(large) {
    height: 650px;
  }

  @include mediaQueryMax(medium) {
    height: 900px;
  }
  @include mediaQueryMax(small) {
    height: 1450px;
  }
}
@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
}

.card {
  padding: 10px !important;
  border: none;
  top: -65%;
}
.boxBacktestExt {
  width: 90% !important;
  margin: 15px !important;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
}
.boxBacktest {
  background-color: #f9f7ff;
  padding: 0 0 0 0 !important;
  width: 100% !important;
  height: 96%;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;

  @include mediaQueryMax(medium) {
    height: 98%;
  }
  @include mediaQueryMax(small) {
    height: 99%;
  }
}

.skynetIcon {
  display: flex;
  align-self: flex-end;
  z-index: 1;
  height: 45px;
}
.skynetLogo {
  height: 35px;
  margin: 10px 0;
}
.label {
  padding: 0px 20px;
  top: -8%;
  height: 75px;
  width: 100%;

  @include mediaQueryMax(medium) {
    top: -4%;
  }
  @include mediaQueryMax(small) {
    top: -3%;
  }
}
.field {
  height: 73%;
  width: 100%;
  display: flex;
  top: -14%;
  margin: 0%;
  padding: 5px 20px;
  flex-direction: column;
  @include mediaQueryMax(large) {
    height: 76%;
  }
  @include mediaQueryMax(medium) {
    height: 72%;
    top: -7%;
  }

  @include mediaQueryMax(small) {
    height: 78%;
    top: -5%;
  }
}

.footer {
  height: 1%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  padding: 5px;
  top: -2%;

  @include mediaQueryMax(medium) {
    top: 11%;
  }
  @include mediaQueryMax(small) {
    width: 100%;
    top: 11%;
  }
}
.fieldUp {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--accent-50);
  @include mediaQueryMax(large) {
    height: 30%;
  }
  @include mediaQueryMax(medium) {
    flex-direction: column;
    height: 35%;
  }
  @include mediaQueryMax(small) {
    height: 25%;
  }
}
.fieldBottom {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include mediaQueryMax(large) {
    height: 80%;
  }
}

.boxInfoInside {
  display: flex;
  width: 50%;
  height: 90%;
  align-items: flex-start;
  flex-direction: column;
  @include mediaQueryMax(medium) {
    width: 100%;
  }
}
.respInfo {
  margin: 20px 0 5px 0;
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%;
}
.respError {
  margin: 5px;
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infoSize {
  min-width: 92px;
  margin: 0 5px 0 0px;
  word-break: break-word;
  @include mediaQueryMax(medium) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.statusSubmetido {
  color: #1da0f2;
}
.statusAguardando {
  color: rgb(247, 169, 0);
}
.statusErro {
  color: rgba(255, 0, 0, 0.7);
}
.fieldStatus {
  width: 90%;
  padding: 5px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  @include mediaQueryMax(medium) {
    margin: 10px 20px;
  }
}

.cardCounter {
  width: 50%;
  padding: 5px;
  margin: 15px 0;
  display: flex;
  align-self: center;

  @include mediaQueryMax(small) {
    width: 90%;
  }
}

.boxDetails {
  width: 100%;
  display: grid;
  justify-items: center;
  margin: 0 0 20px 0;
}
.grid1 {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.grid2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @include mediaQueryMax(small) {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
}
.grid4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  @include mediaQueryMax(medium) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }
  @include mediaQueryMax(small) {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
}
.grid6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  @include mediaQueryMax(large) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.5rem;
  }
  @include mediaQueryMax(medium) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }
  @include mediaQueryMax(small) {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
}
.details {
  width: 150px;
  height: 130px;
  margin: 5px;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  word-break: break-word;
  justify-content: flex-start;
  border: 1.6px solid black;
  cursor: pointer;
  background-color: rgba(31, 134, 219, 0.9);
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 30%);
  @include mediaQueryMax(large) {
    width: 135px;
    height: 115px;
  }
}
.details:hover {
  background-color: rgba(0, 31, 117, 0.7);
  box-shadow: 0px 5px 10px 0.1px rgb(0 0 255 / 50%);
}
.taskExec {
  display: inline;
  position: relative;
  vertical-align: middle;
  margin: 5px !important;
  font-size: 0.8rem;
}
.textDetails {
  margin: 0px 0px 5px 0px !important;
  line-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.copy {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  display: flex;
}
.copy:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25%;
}
.copy:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}
.copybtn {
  color: rgba(148, 148, 148, 0.7);
  transition: 1s all ease;
}

.iconMap {
  color: white;
  font-weight: 300;
  font-size: large;
  margin-right: 2px;
}
.button {
  cursor: pointer;
  align-self: flex-end;
  margin: 0px 10px;

  background-color: #209cee;
  color: white;
  width: 180px;
  height: 34px !important;
  border-radius: 6px;
  border-color: transparent;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @include mediaQueryMax(small) {
    transition: transform 2s ease;
    width: 100%;
    margin: 0 5px 5px 0;
    height: 35px;
  }
}
.button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.icon {
  color: #f9f7ff;
  width: 48px;
  height: 48px;
  font-weight: 600;
  font-size: 2.5rem;
}

.iconMap {
  color: white;
  font-weight: 300;
  font-size: large;
}
.icon-error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 2rem !important;
  transition: 1s all ease;
}
.iconStatus {
  font-size: 2rem !important;
}
.task {
  display: inline;
  position: relative;
  vertical-align: middle;
  float: inline-start;
  margin: 5px !important;
}
.execText {
  display: inline;
  position: relative;
  vertical-align: middle;
  float: inline-start;
  margin: 5px !important;
  font-size: 1rem;
  font-weight: 600;
}

.infoErro {
  width: 95%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
}

.tooltip__content {
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  position: fixed;
  left: 18rem;
  bottom: 3%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  z-index: 99;
  @include mediaQueryMax(medium) {
    left: 0.9rem;
  }
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
</style>
